.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Height of the loader bar */
  background-color: #33A36B; /* YouTube's red color */
  z-index: 9999;
  overflow: hidden;
  transition: height 0.3s ease; /* Smooth height transition */
}

.loader-container.active {
  height: 4px; /* Show the loader bar when active */
}

.loader-bar {
  height: 100%;
  width: 0%;
  background-color: #FF0000; /* Lighter shade of red for the loader bar */
  animation: loader-animation 2s linear infinite; /* Animation for loader progress */
}

@keyframes loader-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
