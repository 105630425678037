.floating-button {
  position: fixed;
  bottom: 65px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33A36B; /* Green background color */
  cursor: pointer;
}




