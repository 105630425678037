.slider {
}

.slider__flex {
    /* width: 1120px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider__images {
    width: 100%;
    /* height: 100%; */
}

.swiper-container2 {
    /* height: 100%; */
    width: 100%;
}

.swiper-container2 *.swiper-slide  {
    width: 100%;
}



.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-bullets-dynamic {
    padding: 8px;
    background-color: black;
    opacity: 0.6;
    border-radius: 50px;
    margin-bottom: 5px !important;
}
.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-main {

    background-color: var(--secondary-color);
}

.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-prev,
.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-next {
    background-color: #ffffff ;
    opacity: 0.4 !important;

}

.swiper-button-prev {
    color: #000 !important;
}
.swiper-button-next {
    color: #000 !important;
}
 
.swiper-container2 .slider__image {
    width: 100%;
  }
  
  .swiper-container2 .slider__image img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* max-height: 1080px; */
    border-radius: 30px;
  }

/* container of indexer of sliders */
.slider .swiper-container1 > div{
    display: flex;
    gap: 2rem;
    padding: 10px 5px;
}

.slider__col {
    display: flex;
    align-items: center;
    margin-left: 62px;
}

.swiper-container1 *.swiper-slide{
    width: fit-content;
}


.swiper-container1 *.slider__image img {
    width: 100%;
    height: 100%;
    border-radius: 30px !;
}



/* shimmer */
.slider-shimmer {
    width: 100%;
    height: 400px;
}


@media (min-width:768px){
    .swiper-container2 .slider__image img{
        height: 350px ;
    }
}
@media (min-width:992px){
    .swiper-container2 .slider__image img{
        height: 500px ;
    }
}
@media (min-width:1200px){
    .swiper-container2 .slider__image img{
        height: 800px ;
    }
}